export default {

    userId: "1112432395606569091",
    github: "lewira",
    description: "Benim adım Lewira. 15 yaşında Türk öğrenciyim. Yazılım ve Tasarımla ilgileniyorum.",
    about: {
        description: "Merhaba. Adım <span class='text-primary'>lewira</span>. <span class='text-primary'>15</span> yaşındayım. <span class='text-primary'>10. sınıf öğrencisiyim</span> ve <span class='text-primary'>Yazılım</span> ile ilgileniyorum. <span class='text-primary'>2022'dan beri</span> kodlama yapıyorum ve bu alanda kendimi geliştirmek istiyorum.",
        liveIn: "Karaman",
        birthday: "1 September",
        gender: "Man"
    }

}
