export default {

    active: [

        {
            name: "Desing Area",
            description: "Tasarımlar satımı olan bir sunucu.",
            image: "https://cdn.discordapp.com/attachments/1162497653305790545/1163201162187644949/desing.PNG?ex=653eb680&is=652c4180&hm=97f2f432f187fc57394751843eeadaae978f95a0eef23be54863432d4838f3bc&",
            role: "Founder",
            link: "https://discord.gg/VghuE9BqSR"
        },
        {
            name: "Asistan",
            description: "Geliş bir discord bot.",
            image: "https://cdn.discordapp.com/attachments/1162497653305790545/1162837747732586557/asistansite.png?ex=653d640b&is=652aef0b&hm=e0fc16a66cd8d20198c4dfde966a34108b462b92c31b47326221782364b27800&",
            role: "Founder",
            link: "https://botasistan.vercel.app/"
        },
        {
            name: "Asil Code",
            description: "Altyapılar yapıp paylaştığım sunucu, kanal.",
            image: "https://cdn.discordapp.com/attachments/1162497653305790545/1162831954945790063/ac.png?ex=653d5ea6&is=652ae9a6&hm=f1656498a17f964cc9702b12871cdc0ffcfe75328c5cbb5dc173cb1a2781ebe9&",
            role: "Founder",
            link: "https://www.youtube.com/channel/UCwnJ6hCjkvmGQ_d9OF3g5eQ"
        },
        {
            name: "Kod Dönüştürücü",
            description: "v13 kodlarınızı büyük oranda v14 çeviren bir site.",
            image: "https://cdn.discordapp.com/attachments/1162497653305790545/1163145447171432489/KOD_DONUSTURUCU.png?ex=653e829c&is=652c0d9c&hm=d9d950154154230cf016fe09864e4db86a4528d99e59f2327bdff7e2cab01f9b&",
            role: "Founder",
            link: "https://code-lewira.vercel.app/"
        },

    ]

}